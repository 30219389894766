<template>
    <b-modal
      id="modal-confirm-equip"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      centered
      title="Confirmar Calibración"
      v-if="equipo"
      @ok="handleOk"
      @cancel="resetInputs"
      @close="resetInputs"
      @hide="resetInputs"
    >
      <p class="font-medium-2">Estas a punto de confirmar que la calibración ha sido realizada.</p>
      <p class="mb-0"><b>TAG:</b> {{equipo.codigo}}</p>
      <p><b>Calibración No.</b> {{equipo.calNo}} de {{ equipo.tiempos[currentMonth].times }}</p>
      <b-form>
        <b-form-group>
          <h5 class="text-sm font-normal">Fecha y Hora de la Calibración</h5>
          <flat-pickr
            v-model="fecha"
            class="form-control"
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
          />
        </b-form-group>
        <b-form-group>
          <label for="email">Comentario</label>
          <b-form-textarea
            id="textarea-state"
            v-model="text"
            :state="validComment"
            placeholder="Máximo de 200 carácteres"
            rows="3"
        />
        </b-form-group>        
      </b-form>
    </b-modal>
</template>

<script>
import {BModal, BForm, BFormGroup, BFormTextarea} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Calibraciones from '@/../apis/Calibraciones'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
    components:{
        BModal, BForm, BFormGroup, BFormTextarea, flatPickr
    },
    data(){
        return{
            text: '',
            fecha:null,
            validComment: null            
        }
    },    
    props:{
      equipo: Object,      
      currentMonth: Number,
      yearCalibration: Number      
    },
    methods:{
       handleOk(){
         const USER = firebase.auth().currentUser.uid
         const dataToSend = {
           fecha: this.fecha,
           comment: this.text,
           firebaseId: this.equipo.key,
           tag: 'calibracion.'+this.equipo.codigo,
           month: this.currentMonth,
           user: USER,
           val: this.equipo.calNo,
           year: this.yearCalibration
         }         
         Calibraciones.actions.enviarRegistro(null,dataToSend).then((response)=>{
           if(response.data.status == 200){
            this.$bvToast.toast(response.data.message, {
              title: 'Correcto...',
              variant: 'success',
              solid: true,
            })
            this.$emit('doneCalibracion', this.currentMonth)
          }else{
            this.$bvToast.toast('Ha ocurrido un error inesperado.', {
              title: 'Error...',
              variant: 'danger',
              solid: true,
            })
          }
         })
       },
       resetInputs(){
          this.text=''
          this.fecha=null
          this.validComment=null
       }       
    },    
    watch:{
      text: function(){
        if(!this.text.length){
          this.validComment = null
        }else{
          this.validComment = true
          if(this.text.length > 200){
            this.validComment = false
          }
        }
      }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>