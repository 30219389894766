<template>
  <b-card
    class="earnings-card mb-0"
  >
    <b-row class="p-1">
      <b-col cols="6">
        <b-card-title class="mb-1 text-primary">
          {{allData.codigo}}
        </b-card-title>
        <div class="font-small-2">
          Este mes
        </div>
        <h5 class="mb-1">
          {{equipoMonth}} Programadas
        </h5>
        <b-card-text class="text-muted font-small-2 nombreEquipo">
          <span class="font-weight-bolder">Denominación: </span><span> {{allData.denominacion}}</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="chartSeries"
        />
      </b-col>      
    </b-row>
    <b-row class="text-center mx-0 pb-1">
        <b-col
            v-if="equipoMonth == 1"
            cols="12"
            class="border-top d-flex align-items-between flex-column py-1 cursor-pointer"
            :class="completed ? 'bg-success' : ''"
            @click="complete(1)"
            :id="'button1Month1-'+allData.codigo"
        >
            <b-tooltip v-if="completedData[0]" :target="'button1Month1-'+allData.codigo" placement="bottom">{{completedData[0]['Comment']}}</b-tooltip>
            <b-card-text class="mb-0" :class="completed ? 'text-white' : 'text-muted'">
            Calibración
            </b-card-text>
            <h3 class="font-weight-bolder mb-50" :class="completed ? 'text-white' : ''">
                {{completed ? 'Completada': 'Pendiente'}}
            </h3>
        </b-col>
        <b-col
            v-if="equipoMonth == 2"
            cols="6"
            class="border-top border-right d-flex align-items-between flex-column py-1 cursor-pointer"
            :class="completed ? 'bg-success' : ''"
            @click="complete(1)"
            :id="'button1Month2-'+allData.codigo"
        >
            <b-tooltip v-if="completedData[0]" :target="'button1Month2-'+allData.codigo" placement="bottom">{{completedData[0]['Comment']}}</b-tooltip>
            <b-card-text class="mb-0" :class="completed ? 'text-white' : 'text-muted'">
            {{completed ? 'Completado': 'Pendiente'}}
            </b-card-text>
            <h3 class="font-weight-bolder mb-50" :class="completed ? 'text-white' : ''">
                1/2
            </h3>
        </b-col>

        <b-col
            v-if="equipoMonth == 2"
            cols="6"
            class="border-top d-flex align-items-between flex-column py-1 cursor-pointer"
            :class="completed==2 ? 'bg-success' : ''"
            @click="complete(2)"
            :id="'button2Month2-'+allData.codigo"
        >
            <b-tooltip v-if="completedData[1]" :target="'button2Month2-'+allData.codigo" placement="bottom">{{completedData[1]['Comment']}}</b-tooltip>
            <b-card-text class="mb-0" :class="completed==2 ? 'text-white' : 'text-muted'">
            {{completed ? 'Completado': 'Pendiente'}}
            </b-card-text>
            <h3 class="font-weight-bolder mb-0" :class="completed==2 ? 'text-white' : ''">
                2/2
            </h3>
        </b-col>
        </b-row>        
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton,BTooltip
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#079748'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
    BButton,    
    BTooltip
  },
  data() {
    return {
      chartSeries: [0],
      earningsChart: {
        series: [0,0],
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },          
          labels: ['Completado', 'Pendientes'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix                      
                      return `${parseInt(val)}%`
                    },
                  },                  
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  props:{      
      equipoMonth: Number,
      completed: Number,
      allData: Object,
      completedData: Array
  },
  methods:{
      complete(val){                      
          if(val <= this.completed) return
          if(val == 2 && this.completed != 1) return
          let dataEmit = this.allData
          dataEmit.calNo = val         
          dataEmit = JSON.parse(JSON.stringify(dataEmit))
          this.$emit('confirmComplete', dataEmit)
      },
      renderChart(){
        if(this.completed == this.equipoMonth) {
          this.chartSeries = [100]
        }
        else if(this.completed < this.equipoMonth && this.completed){
          this.chartSeries = [50,50]
        }
        else{
          this.chartSeries = [0]
        }
      }
  },
  created(){      
    this.renderChart()
  },
  watch:{
      completed: function(){        
          this.renderChart()
      }
  }
}
</script>
<style>
.earnings-card .card-body{
    padding: 0;
}
.nombreEquipo{
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>