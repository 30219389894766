<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Mes"
            class="fieldMargin w-100"
          >
            <b-form-select
              v-model="currentMonth"
              :options="allMonths"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Año"
            class="fieldMargin w-100"
          >
            <b-form-select
              v-model="yearCalib"
              :options="allYears"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" />
        <b-col cols="4">
          <b-form-group
            label="Buscar"
            label-for="buscar"
          >
            <b-form-input
              id="buscar"
              v-model="search"
              placeholder="Buscar equipo"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col md="4">
        <GoalOverview :data-resumen="goalData" />
      </b-col>
      <template v-if="currentMonth != 12">
        <template v-for="(equipo, key) in allEquipos">
          <b-col
            v-if="Number(equipo.tiempos[currentMonth].times) && exist(equipo.codigo)"
            :key="key"
            md="4"
          >
            <single-equipo
              class="w-full"
              :equipo-month="Number(equipo.tiempos[currentMonth].times)"
              :completed="!itemsCompleted[equipo.key] ? 0 : itemsCompleted[equipo.key].length"
              :all-data="equipo"
              :completed-data="!itemsCompleted[equipo.key] ? [] : itemsCompleted[equipo.key]"
              @confirmComplete="toComplete"
              @timeToComplete="$event = timeToComplete"
            />
          </b-col>
        </template>
      </template>
      <modalConfirm
        :equipo="confirmacionData"
        :current-month="currentMonth"
        :time-to-complete="timeToComplete"
        :year-calibration="yearCalib"
        @doneCalibracion="getDataMonth"
      />
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormSelect, BFormInput,
} from 'bootstrap-vue'
import firebase from 'firebase/app'
import singleEquipo from './Components/singleEquipo.vue'
import modalConfirm from './Components/ModalConfirm.vue'
import GoalOverview from './Components/GoalOverview.vue'
import Calibraciones from '@/../apis/Calibraciones'
import 'firebase/database'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    singleEquipo,
    modalConfirm,
    GoalOverview,
  },
  data() {
    return {
      allEquipos: null,
      currentMonth: null,
      confirmacionData: null,
      timeToComplete: null,
      itemsCompleted: [],
      search: null,
      resultsResponse: null,
      allMonths: [
        { value: 0, text: 'Enero' },
        { value: 1, text: 'Febrero' },
        { value: 2, text: 'Marzo' },
        { value: 3, text: 'Abril' },
        { value: 4, text: 'Mayo' },
        { value: 5, text: 'Junio' },
        { value: 6, text: 'Julio' },
        { value: 7, text: 'Agosto' },
        { value: 8, text: 'Septiembre' },
        { value: 9, text: 'Octubre' },
        { value: 10, text: 'Noviembre' },
        { value: 11, text: 'Diciembre' },
      ],
      totalGoal: 0,
      goalData: { total: 0, completed: 0 },
      allYears: [],
      yearCalib: null,
      pathFB: '',
    }
  },
  watch: {
    currentMonth() {
      this.getDataMonth()
    },
    yearCalib() {
      this.getDataMonth()
    },
  },
  mounted() {
    console.log('MOUNTED')
    if (this.$route.meta.isBio) {
      this.pathFB = 'calibracionesBio/equipos'
    } else {
      this.pathFB = 'calibraciones/equipos'
    }
    this.currentMonth = new Date().getMonth()
    // this.getDataMonth(this.currentMonth)
    this.yearCalib = new Date().getFullYear()
    for (let i = (this.yearCalib - 10); i < (this.yearCalib + 10); i++) {
      this.allYears.push(i)
    }
  },
  methods: {
    toComplete(event) {
      this.confirmacionData = event
      this.$bvModal.show('modal-confirm-equip')
    },
    getDataMonth(val) {
      if (val === 12) return
      this.itemsCompleted = []
      const obj = {
        month: 0,
        year: 0,
      }
      obj.year = this.yearCalib
      obj.month = this.currentMonth
      Calibraciones.actions.obtenerRegistros(obj).then(response => {
        this.resultsResponse = response.data.results
        const result = response.data.results
        this.itemsCompleted = []
        result.forEach(element => {
          if (!this.itemsCompleted[element.FirebaseID]) {
            this.itemsCompleted[element.FirebaseID] = []
            this.itemsCompleted[element.FirebaseID].push(element)
          } else {
            this.itemsCompleted[element.FirebaseID].push(element)
          }
        })
        // Datos de Firebase
        this.firebaseData()
      })
    },
    firebaseData() {
      if (this.pathFB) {
        firebase.database().ref(this.pathFB).on('value', snapshot => {
          this.allEquipos = []
          if (snapshot.exists()) {
            Object.entries(snapshot.val()).forEach(([key, value]) => {
              value.key = key
              this.allEquipos.push(value)
            })
            this.goalMonth()
          }
        })
      }
    },
    goalMonth() {
      this.totalGoal = 0
      let completed = 0
      Object.entries(this.allEquipos).forEach(([key, value]) => {
        this.totalGoal += Number(value.tiempos[this.currentMonth].times)
        if (this.itemsCompleted[value.key]) {
          completed += this.itemsCompleted[value.key].length
        }
      })
      this.goalData = {
        total: this.totalGoal,
        completed,
      }
    },
    exist(tag) {
      const busqueda = this.search ? this.search.toUpperCase() : ''
      if (busqueda) {
        if (tag.indexOf(busqueda) != -1) {
          return true
        }

        return false
      }
      return true
    },
  },
}
</script>
<style>
.fieldMargin{
    margin: 0;
}
</style>
